/* Fullcalendar */

.external-event {
    padding: 15px !important;
    margin-bottom: 5px !important;
    color: #fff !important;
    color: rgba(255,255,255,0.7) !important;
    .border-radius(3px) !important;
}
.calendar-event .external-event h5 {
    color: #fff !important;
    margin: 0 0 5px !important;
}
.calendar-event .external-event p {
    margin: 0 !important;
    line-height: 16px !important;
    font-weight: 300 !important;
}
.fc-content {
    clear: none !important;
}
.fc-state-highlight {
    background: #f0f3f4 !important;
}
.fc-widget-header, .fc-widget-content {
    border-color: #e2e7eb !important;
}
.fc-widget-header {
    color: #242a30 !important;
    font-weight: 600 !important;
    padding: 3px 15px !important;
}
.fc-grid .fc-day-number {
    padding: 3px 5px !important;
}
.fc-content .fc-event {
    border: none !important;
    padding: 5px !important;
    text-align: center !important;
    background: #2d353c;
}
.fc-event-time {
    font-size: 14px !important;
    margin-right: 5px !important;
}
.fc-event .fc-event-title {
    font-size: 14px !important;
    display: block !important;
}
.fc-event .fc-event-title small {
    display: block !important;
    font-size: 12px !important;
    font-weight: 300 !important;
    line-height: 16px !important;
    color: #ccc !important;
    color: rgba(255,255,255,0.8) !important;
}
.fc-event .fc-event-icons {
    font-size: 18px !important;
    display: block !important;
}
.fc-event-container a:hover,
.fc-event-container a:focus {
    color: #fff !important;
    text-decoration: underline !important;
}
.fc-state-default {
    background: #fff !important;
    border: 1px solid #ccc !important;
    line-height: 1.42857143 !important;
    padding: 6px 12px !important;
    color: #333 !important;
    font-weight: normal !important;
    height: auto !important;
}
.fc-header .fc-button {
    margin-bottom: 15px !important;
    .box-shadow(none) !important;
}
.fc-header .fc-button:not(.fc-state-disabled):hover,
.fc-header .fc-button:not(.fc-state-disabled):focus {
    background: #eee !important;
}
.fc-header .fc-button.fc-state-down, 
.fc-header .fc-button.fc-state-active {
    background: #eee !important;
    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125)) !important;
}
.fc-text-arrow {
    font-size: 14px !important;
    line-height: 16px !important;
}
.fc-header-title h2 {
    line-height: 31px !important;
    font-size: 24px !important;
}