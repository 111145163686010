/* Nav Setting */

.nav > li > a {
    color: #6e7179;
}
.nav > li > a:hover,
.nav > li > a:focus {
    color: #333;
    background: #fafafa;
}


/* Nav Tabs */

.nav-tabs,
.nav-tabs > li > a,
.nav-tabs > li.active > a, 
.nav-tabs > li.active > a:hover, 
.nav-tabs > li.active > a:focus,
.nav-tabs.nav-justified > li > a,
.nav-tabs.nav-justified > .active > a, 
.nav-tabs.nav-justified > .active > a:hover, 
.nav-tabs.nav-justified > .active > a:focus {
    border: none !important;
}
.nav-tabs > li.active > a, 
.nav-tabs > li.active > a:hover, 
.nav-tabs > li.active > a:focus,
.nav-tabs.nav-justified > .active > a, 
.nav-tabs.nav-justified > .active > a:hover, 
.nav-tabs.nav-justified > .active > a:focus {
    color: @dark_black;
}
.nav-tabs {
    background: #c1ccd1;
    .border-radius(5px 5px 0 0);
}
.nav-tabs.nav-tabs-inverse {
    background: @dark_black;
}
.nav-tabs.nav-justified > li > a {
    .border-radius(3px 3px 0 0);
}
.nav-tabs.nav-tabs-inverse > li.active > a,
.nav-tabs.nav-tabs-inverse > li.active > a:hover,
.nav-tabs.nav-tabs-inverse > li.active > a:focus {
    background: @white;
}
.nav-tabs.nav-tabs-inverse > li > a:hover,
.nav-tabs.nav-tabs-inverse > li > a:focus {
    color: @white;
    background: none;
}
.nav-tabs > li,
.nav-tabs.nav-justified > li {
    margin-bottom: 0;
}
.nav-tabs > li > a {
    margin-right: 5px;
    line-height: 20px;
}


/* Nav Pills */

.nav-pills {
    margin-bottom: 10px;
}
.nav-pills > li + li {
    margin-left: 5px;
}
.nav-pills > li > a {
    .border-radius(3px);
}
.nav-pills > li.active > a, 
.nav-pills > li.active > a:hover, 
.nav-pills > li.active > a:focus {
    background: @dark_black;
}
.nav-stacked > li + li {
    margin-left: 0;
    margin-top: 5px;
}


/* Tab Content */

.tab-content {
    padding: 15px;
    margin-bottom: 20px;
    background: @white;
    .border-radius(3px);
}
.nav-tabs + .tab-content {
    .border-radius(0 0 3px 3px);
}