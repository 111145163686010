@import "../color_admin/less/style";

.navbar-brand > img{
  height: 90%;
}

th{
  font-size: 12px;
}

#data-table th, .fixedHeader th{
  font-size: 10px;
}

#data-table td, .fixedHeader td{
   font-size: 10px;
 }

table.dataTable tr.DTTT_selected.odd td{
  background-color: #9FAFD1;
}

table.dataTable tr.DTTT_selected.even td{
  background-color: #B0BED9;
}

#data-table th input[type=checkbox]{
  font-size: 10px;
  height: 20px;
  margin-top: -2px;
}

#data-table tfoot select{
  font-size: 10px;
  width:100%;
}

.multiselect-search{
  height:28px;
}

#data-table tfoot {
  display: table-header-group;
}

.ColVis{
  display: none;
}

.multiselect-container {
  max-height: 500px;
  overflow-y: auto!important;
  overflow-x: hidden!important;
}

.DTTT_button.btn-warning,
.DTTT_button.btn-primary,
.DTTT_button.btn-danger,
.DTTT_button.btn-success{
  color:white!important;
}

.DTTT_disabled{
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .65;
}

.drag-drop{
  margin-left: 10px;
  margin-bottom: 10px;
  border: 5px dashed #ccc;
  width: 360px;
  height: 100px;text-align: center;
}