/* 404 Error Page */

.error {
    margin: 0 auto;
    text-align: center;
}
.error-code,
.error-content {
    position: absolute;
    left: 0;
    right: 0;
}
.error-code {
    bottom: 60%;
    color: @black;
    font-size: 96px;
    line-height: 100px;
}
.error-content {
    top: 40%;
    bottom: 0;
    padding-top: 15px;
    background: #2d353c;
}
.error-message {
    color: #fff;
    font-size: 24px;
}
.error-desc {
    font-size: 12px;
    color: #647788;
}