/* Widget Stats */

.widget {
    margin-bottom: 20px;
    color: #fff;
    padding: 15px;
    overflow: hidden;
    .border-radius(3px);
    
    &.widget-stats {
        position: relative;
        
        & .stats-info {
            & h4 {
                font-size: 12px;
                margin: 5px 0;
                color: #fff;
            }
            & p {
                font-size: 24px;
                font-weight: 300;
                margin-bottom: 0;
            }
        }
        & .stats-icon {
            font-size: 42px;
            height: 56px;
            width: 56px;
            text-align: center;
            line-height: 56px;
            margin-left: 15px;
            color: #fff;
            position: absolute;
            right: 15px;
            top: 15px;
            .opacity(0.5);
            
            &.stats-icon-lg {
                font-size: 52px;
                top: 12px;
                right: 21px;
            }
        }
        & .stats-link a {
            display: block;
            margin: 15px -15px -15px;
            padding: 7px 15px;
            background: url(../img/transparent/black-0.4.png);
            background: rgba(0,0,0,0.4);
            text-align: right;
            color: #ddd;
            font-weight: 300;
            text-decoration: none;
            min-height: 20px;
            
            &:hover,
            &:focus {
                background: url(../img/transparent/black-0.6.png);
                background: rgba(0,0,0,0.6);
                color: #fff;
            }
        }
        & .stats-icon
        & .stats-title {
            position: relative;
            line-height: 1.1;
            font-size: 12px;
            margin: 2px 0 7px;
        }
        & .stats-desc {
            color: #fff;
            color: rgba(255,255,255,0.6);
            font-weight: 300;
            margin-bottom: 0;
        }
        & .stats-number {
            font-size: 24px;
            font-weight: 300;
            margin-bottom: 10px;
        }
        & .stats-progress {
            background: url('../img/transparent/black-0.2.png');
            background: rgba(0,0,0,0.2);
            height: 2px;
            margin: 0 -15px 12px;
            
            & .progress-bar {
                background: #fff;
            }
        }
    }
}