/* List Group */

a.list-group-item.active, 
a.list-group-item.active:hover, 
a.list-group-item.active:focus {
    background: @blue;
}
a.list-group-item.active > .badge, 
.nav-pills > .active > a > .badge {
    color: #242a30;
}
.nav.nav-pills.nav-sm > li {
    margin: 0 0 3px;
    
    & a {
        padding: 8px 10px;
        line-height: 1.5;
    }
}