/* Login & Register Page with News Feed Setting */

.login.login-with-news-feed,
.register.register-with-news-feed {
    width: 100%;
    margin: 0;
    padding: 0;
}
.login.login-with-news-feed .news-feed,
.register.register-with-news-feed .news-feed {
    position: fixed;
    left: 0;
    right: 500px;
    top: 0;
    bottom: 0;
    -webkit-transform: translateZ(0);
    overflow: hidden;
}
.login.login-with-news-feed .news-image,
.register.register-with-news-feed .news-image {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}
.login.login-with-news-feed .news-image img,
.register.register-with-news-feed .news-image img {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    max-height: 100%;
    min-width: 100%;
    top: -1960px;
    bottom: -1960px;
    left: -1960px;
    right: -1960px;
    margin: auto;
}
.login.login-with-news-feed .news-caption,
.register.register-with-news-feed .news-caption {
    background: rgba(255,255,255,0.15);
    background: rgba(0, 0, 0, 0.7);
    color: #999;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px 60px;
    font-size: 14px;
    z-index: 20;
    font-weight: 300;
    min-width: 680px;
}
.login.login-with-news-feed .news-caption .caption-title,
.register.register-with-news-feed .news-caption .caption-title {
    color: #fff;
    color: rgba(255,255,255,0.8);
    font-weight: 300;
    font-size: 28px;
}
.login.login-with-news-feed .right-content,
.register.register-with-news-feed .right-content {
    float: right;
    width: 500px;
}
.login.login-with-news-feed .login-content,
.register.register-with-news-feed .register-content {
    width: auto;
    padding: 30px 60px;
}
.register.register-with-news-feed .register-content {
    padding: 20px 60px 30px;
}
.login.login-with-news-feed .login-header,
.register.register-with-news-feed .register-header {
    position: relative;
    top: 0;
    margin: 0;
    left: 0;
    right: 0;
    padding: 100px 60px 0;
}
.register.register-with-news-feed .register-header {
    padding: 60px 60px 0;
    font-size: 32px;
}
.register.register-with-news-feed .register-header small {
    color: #707478;
    display: block;
    font-size: 14px;
    margin-top: 10px;
    line-height: 20px;
}
.login.login-with-news-feed .login-header .brand {
    color: #242a30;
}
.login.login-with-news-feed .login-header .brand small {
    color: #707478;
}
.login.login-with-news-feed .login-header .icon {
    top: 98px;
    right: 10px;
}