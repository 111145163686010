/* Gallery */

.gallery {
    margin: 0 -10px;
    
    & .image {
        width: 25%;
        display: block;
        margin-right: -10px;
        overflow: hidden;
        padding: 10px;
        
        & img {
            width: 100%;
            height: 200px;
            .border-radius(3px 3px 0 0);
        }
    }
    & .image-inner {
        position: relative;
        background: #fff;
        .border-radius(3px 3px 0 0);
        & a {
            .transition(all .2s linear);
            
            &:hover,
            &:focus {
                .opacity(0.8);
            }
        }
    }
    & .image-caption {
        position: absolute;
        top: 15px;
        left: 0;
        background: url(../img/transparent/black-0.6.png);
        background: rgba(0,0,0,0.6);
        color: #fff;
        padding: 5px 15px;
        margin: 0;
    }
    & .image-info {
        background: #fff;
        padding: 15px;
        .border-radius(0 0 3px 3px);
        
        & .title {
            margin: 0 0 10px;
            line-height: 18px;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        & .rating {
            line-height: 20px;
            margin: 0 0 3px;
            
            & span.star {
                font-family: FontAwesome;
                display: inline-block;
                
                &:before {
                    content: "\f005";
                    color: #999;
                }
                &.active:before {
                    color: #FF8500;
                }
            }
        }
        & .desc {
            line-height: 16px;
            font-size: 12px;
            height: 48px;
            overflow: hidden;
        }
    }
}
.gallery-option-set {
    display: block;
    margin-bottom: -5px;
    
    & .btn {
        margin: 0 5px 5px 0;
    }
}