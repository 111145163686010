/* Sparkline */

.jqstooltip {
    border: none !important;
    background: #333 !important;
    width: auto !important;
    height: auto !important;
    background: url(../img/transparent/black-0.8.png) !important;
    background: rgba(0,0,0,0.8) !important;
    .border-radius(4px);
}