/* Bootstrap Timepicker */

.bootstrap-timepicker .bootstrap-timepicker-widget.dropdown-menu + .form-control {
    .border-radius(4px 0 0 4px);
}
.bootstrap-timepicker-widget table td input {
    .border-radius(4px) !important;
}
.bootstrap-timepicker-widget.dropdown-menu.open {
    border: 1px solid #ddd !important;
    max-width: 200px;
}
.bootstrap-timepicker-widget table td a:hover,
.bootstrap-timepicker-widget table td a:focus {
    background: @blue !important;
    border: none !important;
    color: #fff;
}
