/* Sidebar & sidebar elements */

.sidebar {
    width: 220px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding-top: 54px;
    background: @black;
    z-index: 10;
    -webkit-transform: translateZ(0);
}
.sidebar-bg {
    background: @black;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 220px;
    z-index: 0;
}
.sidebar .nav > li:before,
.sidebar .nav > li:after,
.sidebar .nav > li > a:before,
.sidebar .nav > li > a:after {
    .clearfix();
}
.sidebar .nav > li > a {
    padding: 8px 20px;
    line-height: 20px;
    color: @sidebar_text_color;
}
.sidebar.sidebar-grid .nav > li > a {
    border-bottom: 1px solid #383f46;
    border-top: 1px solid #383f46;
}
.sidebar.sidebar-grid .nav > li.active > a {
    border-color: #2d353c;
    z-index: 10;
}
.sidebar.sidebar-grid .nav > li + li {
    margin-top: -1px;
}
.sidebar .nav > li.active > a .label.label-theme,
.sidebar .nav > li.active > a .badge {
    background: url(../img/transparent/black-0.4.png);
    background: rgba(0,0,0,0.4);
}
.sidebar .nav > li.expand > a,
.sidebar .nav > li > a:hover,
.sidebar .nav > li > a:focus {
    background: @dark_black;
    color: @sidebar_text_color;
}
.sidebar .nav > li.active > a, 
.sidebar .nav > li.active > a:hover, 
.sidebar .nav > li.active > a:focus {
    color: #fff;
    background: @green;
}
.sidebar .nav > li.active > a .label.label-success,
.sidebar .nav > li.active > a .badge.badge-success {
    background: @dark_green;
}
.sidebar .nav > li > a i {
    float: left;
    margin-right: 15px;
    width: 14px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
}
.sidebar .nav > li > a i[class*="ion-"] {
    margin-right: 11px;
    width: 18px;
    font-size: 18px;
}
.sidebar .nav > li > a .badge {
    margin-top: 1px;
    padding: 3px 8px;
    background: @darker_black;
    font-weight: 300;
    font-size: 10px;
}
.sidebar .nav > li > a .caret {
    float: right;
    margin-top: 9px;
}
.sidebar .has-sub.active > .sub-menu {
    display: block;
}
.sidebar .sub-menu {
    list-style-type: none;
    padding: 10px 0 10px 30px;
    margin: 0;
    background: @darker_black;
    position: relative;
    display: none;
}
.sidebar .sub-menu:before {
    content: '';
    position: absolute;
    left: 26px;
    top: 0;
    bottom: 0;
    width: 2px;
    background: #10181F;
}
.sidebar .sub-menu .sub-menu {
    padding: 0 0 0 30px;
    background: none;
}
.sidebar .sub-menu > li > a {
    padding: 5px 20px;
    display:block;
    font-weight: 300;
    color: #889097;
    text-decoration: none;
    position: relative;
}
.sidebar .sub-menu > li > a:before {
    content: '\f10c';
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    font-size: 7px;
    color: #889097;
    top: 50%;
    margin-top: -4px;
    margin-left: -6px;
}
.sidebar .sub-menu > li > a:hover,
.sidebar .sub-menu > li > a:focus,
.sidebar .sub-menu > li.active > a,
.sidebar .sub-menu > li.active > a:hover,
.sidebar .sub-menu > li.active > a:focus {
    color: #fff;
}
.sidebar .nav > li li.has-sub.active > a {
    color: #889097;
}
.sidebar .sub-menu > li.active > a:before {
    color: @green;
}
.sidebar .nav .sub-menu > li > a .caret {
    float: right; 
    margin-top: 7px;
}
.sidebar .nav > li.nav-header {
    margin: 0;
    padding: 10px 20px;
    line-height: 20px;
    font-size: 11px;
    color: #6d7983;
}
.sidebar .nav > li.nav-header a {
    padding: 0;
    margin: 0;
    display: inline;
}
.sidebar .nav > li.nav-header a:hover,
.sidebar .nav > li.nav-header a:focus {
    background: none;
    color: #fff;
}
.sidebar .nav > li.nav-header a i {
    float: none;
    margin: 0;
}
.sidebar .nav > li.nav-profile {
    padding: 20px;
    color: #fff;
    background: @darker_black;
}
.sidebar .nav > li.nav-profile a {
    padding: 0;
}
.sidebar .nav > li.nav-profile .image {
    float: left;
    width: 34px;
    height: 34px;
    margin-top: 2px;
    margin-right: 15px;
    overflow: hidden;
    .border-radius(50%);
}
.sidebar .nav > li.nav-profile .image img {
    max-width: 100%;
    max-height: 100%;
}
.sidebar .nav > li.nav-profile .info {
    font-size: 14px;
}
.sidebar .nav > li.nav-profile .info small {
    display: block;
    color: #889097;
}
.sidebar .nav > li.nav-widget {
    padding: 10px 20px;
}
.sidebar .nav > li.nav-widget i {
    margin-right: auto;
}
.page-sidebar-minified .sidebar {
    width: 60px;
    position: absolute;
}
.page-sidebar-minified .sidebar-bg {
    width: 60px;
}
.page-sidebar-minified .content {
    margin-left: 60px;
}
.page-sidebar-minified .footer {
    margin-left: 85px;
}
.page-sidebar-minified .sidebar .slimScrollDiv,
.page-sidebar-minified .sidebar .slimScrollDiv > div {
    overflow: visible !important;
}
.page-sidebar-minified .sidebar .nav > li > a {
    padding: 12px 20px;
}
.page-sidebar-minified .sidebar .nav-profile,
.page-sidebar-minified .sidebar .nav-header,
.page-sidebar-minified .sidebar .nav > li > a > span {
    display: none;
}
.page-sidebar-minified .sidebar .caret {
    position: absolute;
    top: 9px;
    right: 9px;
    border: 4px solid transparent;
    border-left: 4px solid;
}
.page-sidebar-minified .sidebar .sub-menu .caret {
    top: 10px;
    margin-top: 0 !important;
}
.page-sidebar-minified .sidebar .nav > li > a > i {
    margin: 0;
}
.page-sidebar-minified .sidebar .nav li.has-sub > .sub-menu {
    position: absolute;
    left: 100%;
    top: 0;
    width: 220px;
    display: none;
    padding: 10px 0 10px 30px;
    margin: 0;
    background: @darker_black;
}
.page-sidebar-minified .sidebar .nav > li.has-sub:hover > a,
.page-sidebar-minified .sidebar .nav > li.has-sub:focus > a {
    background: #232a2f;
}
.page-sidebar-minified .sidebar li.has-sub > .sub-menu {
    display: none !important;
}
.page-sidebar-minified .sidebar li.has-sub:hover > .sub-menu,
.page-sidebar-minified .sidebar li.has-sub:focus > .sub-menu {
    display: block !important;
    overflow: visible !important;
}
.page-sidebar-minified .sidebar .nav li.has-sub {
    position: relative;
}
.page-sidebar-minified .sidebar .nav > li.has-sub li.has-sub .sub-menu {
    margin-top: -10px;
}
.sidebar-minify-btn {
    margin: 10px 0;
    float: right;
    padding: 5px 20px 5px 10px !important;
    background: @darker_black;
    color: #fff;
    .border-radius(20px 0 0 20px);
}
.sidebar-minify-btn i {
    margin: 0 !important;
    color: #fff;
}
.page-sidebar-minified .sidebar-minify-btn i:before {
    content: '\f101';
}
.page-sidebar-minified.page-with-right-sidebar .content {
    margin-right: 60px;
    margin-left: 0;
}
.page-sidebar-minified.page-with-right-sidebar .footer {
    margin-right: 85px;
    margin-left: 25px;
}
.page-sidebar-minified.page-with-right-sidebar .sub-menu {
    left: auto !important;
    right: 100%;
}
.page-sidebar-minified.page-with-right-sidebar .nav > li.has-sub > a .caret {
    position: absolute;
    left: 5px;
    border: 4px solid transparent;
    border-right: 4px solid;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar .sub-menu .caret {
    left: 0;
    top: 2px;
    border-left: 4px solid transparent !important;
    border-right: 4px solid !important;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar .nav li.has-sub > .sub-menu {
    padding: 10px 30px 10px 0;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar .sub-menu:before {
    right: 26px;
    left: auto;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar .sub-menu > li > a:before {
    right: 0;
    left: auto;
    margin-right: -6px;
    margin-left: 0;
}
.page-sidebar-minified.page-with-right-sidebar .sidebar-minify-btn i:before {
    content: '\f100';
}
.page-sidebar-minified .sidebar .slimScrollBar,
.page-sidebar-minified .sidebar .slimScrollRail {
    display: none !important;
}



/* -------------------------------
   Update - V1.7
------------------------------- */

/* Light Sidebar Setting */

.page-with-light-sidebar .sidebar,
.page-with-light-sidebar .sidebar-bg {
    background: #fff;
}
.page-with-light-sidebar .sidebar .nav > li > a,
.flat-black .page-with-light-sidebar .sidebar .nav > li > a {
    color: #2d353c;
}
.page-with-light-sidebar .sidebar .nav > li.nav-profile {
    background: #f0f3f4;
    color: #2d353c;
}
.page-with-light-sidebar .sidebar .nav > li .sub-menu,
.page-sidebar-minified.page-with-light-sidebar .sidebar .nav li.has-sub > .sub-menu,
.page-sidebar-minified.page-with-light-sidebar .sidebar .nav > li.has-sub:focus > a, 
.page-sidebar-minified.page-with-light-sidebar .sidebar .nav > li.has-sub:hover > a {
    background: #f0f3f4;
}
.page-with-light-sidebar .sidebar .nav > li.nav-profile .info small {
    color: rgba(0,0,0,0.7);
}
.page-with-light-sidebar .sidebar .nav > li.active > a {
    color: #fff;
}
.page-with-light-sidebar .sidebar .nav > li .sub-menu:before {
    background: #B1B9C0;
}
.page-with-light-sidebar .sidebar .nav > li .sub-menu > li > a:before {
    content: '\f111';
    color: #B1B9C0;
}
.page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn,
.page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn:hover,
.page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn:focus,
.page-with-light-sidebar .sidebar .nav > li.active.expand > a,
.page-with-light-sidebar .sidebar .nav > li.active > a,
.page-with-light-sidebar .sidebar .nav > li.active > a:hover,
.page-with-light-sidebar .sidebar .nav > li.active > a:focus,
.page-with-light-sidebar.page-sidebar-minified .sidebar .nav > li.active > a,
.page-with-light-sidebar.page-sidebar-minified .sidebar .nav > li.active:hover > a,
.page-with-light-sidebar.page-sidebar-minified .sidebar .nav > li.active:focus > a {
    color: #fff;
    background: #2d353c;
}
.flat-black .page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn,
.flat-black .page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn:hover,
.flat-black .page-with-light-sidebar .sidebar .nav > li .sidebar-minify-btn:focus,
.flat-black .page-with-light-sidebar .sidebar .nav > li.active.expand > a,
.flat-black .page-with-light-sidebar .sidebar .nav > li.active > a,
.flat-black .page-with-light-sidebar .sidebar .nav > li.active > a:hover,
.flat-black .page-with-light-sidebar .sidebar .nav > li.active > a:focus {
    background: #3a3a3a;
}
.page-with-light-sidebar .sidebar .nav > li.expand > a,
.page-with-light-sidebar .sidebar .nav > li > a:hover,
.page-with-light-sidebar .sidebar .nav > li > a:focus {
    background: #E6E9EA;
}
.page-with-light-sidebar .sidebar .nav > li .sub-menu > li.active > a:before {
    color: #575d63;
}
.page-with-light-sidebar .sidebar .sub-menu > li > a {
    font-weight: normal;
}
.flat-black .page-with-light-sidebar .sidebar .sub-menu > li.active > a,
.page-with-light-sidebar .sidebar .sub-menu > li.active > a, 
.page-with-light-sidebar .sidebar .sub-menu > li.active > a:focus, 
.page-with-light-sidebar .sidebar .sub-menu > li.active > a:hover, 
.page-with-light-sidebar .sidebar .sub-menu > li > a:focus, 
.page-with-light-sidebar .sidebar .sub-menu > li > a:hover {
    color: #333;
}
.flat-black .page-with-light-sidebar.page-sidebar-minified .sidebar .nav li.has-sub > .sub-menu, 
.flat-black .page-with-light-sidebar .sidebar .nav > li.nav-profile, 
.flat-black .page-with-light-sidebar .sidebar .sub-menu {
    background: #f5f5f5;
}
.page-with-light-sidebar.page-sidebar-minified .sidebar .nav > li.active > a > i {
    color: #fff;
}


/* Wide Sidebar Setting */

.page-with-wide-sidebar .sidebar,
.page-with-wide-sidebar.page-sidebar-minified .sidebar-bg.sidebar-right, 
.page-with-wide-sidebar.page-sidebar-minified .sidebar.sidebar-right {
    width: 250px;
}
.page-with-wide-sidebar .content {
    margin-left: 250px;
}
.page-with-wide-sidebar.page-with-right-sidebar .content {
    margin-right: 250px;
    margin-left: 0;
}
.page-with-wide-sidebar.page-with-two-sidebar .content {
    margin-left: 250px;
    margin-right: 250px;
}
.page-with-wide-sidebar.page-sidebar-minified .sidebar {
    width: 60px;
}
.page-with-wide-sidebar.page-sidebar-minified .content {
    margin-left: 60px;
}