/* Registered Users List Setting */

.registered-users-list {
    list-style-type: none;
    margin: 7.5px;
    padding: 0;
    
    & > li {
        width: 25%;
        font-size: 12px;
        float: left;
        padding: 7.5px;
        color: #333;
    
        & .username {
            font-size: 12px;
            color: #333;
            margin-bottom: 0;
        
            & small {
                display: block;
                font-size: 11px;
                color: #707478;
                margin-top: 3px;
            }
        }
        & img {
            max-width: 100%;
            .border-radius(3px);
        }
        & > a {
            display: block;
            margin-bottom: 10px;
        }
    }
}
