/* Progress bar */

.progress {
    .box-shadow(none);
    background: #e2e7eb;
}
.progress-xs {
    height: 5px;
}
.progress-xs .progress-bar {
    line-height: 5px;
}
.progress-sm {
    height: 10px;
}
.progress-sm .progress-bar {
    line-height: 10px;
}
.progress-lg {
    height: 30px;
}
.progress-lg .progress-bar {
    line-height: 30px;
}
.progress-bar {
    .box-shadow(none);
    background: @blue;
}
.progress-bar.progress-bar-success {
    background-color: @green;
}
.progress-bar.progress-bar-info {
    background-color: @aqua;
}
.progress-bar.progress-bar-warning {
    background-color: @orange;
}
.progress-bar.progress-bar-danger {
    background-color: @red;
}
.progress-bar.progress-bar-inverse {
    background-color: @black;
}