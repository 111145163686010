/* Flot Chart */

.legendColorBox > div {
    margin-right: 5px !important;
    margin-top: 1px !important;
    border: none !important;
    .border-radius(5px) !important;
}
.legend > table tr td.legendColorBox {
    padding-left: 5px !important;
}
.legend > table tr td {
    padding: 4px 0 5px 0 !important;
}
.legend > div {
    border-radius: 4px !important;
    background: #000 !important;
}
.legend > table tr td.legendLabel {
    padding-right: 10px !important;
    color: #fff;
}
.legend > table tr + tr td {
    padding-top: 0px !important;
}
.legendColorBox > div > div {
    border-width: 4px !important;
    .border-radius(5px) !important;
}
.tickLabel,.legendLabel {
    font-size: 11px !important;
}
.tickLabel {
    color: #707478 !important;
}
.flot-tooltip {
    position: absolute;
    display: none;
    border: 0;
    padding: 6px 10px 7px;
    color: #fff;
    font-size: 11px;
    background: url(../images/black-0.8.png);
    background-color: rgba(0,0,0,0.8);
    .border-radius(5px);
    .opacity(1.0);
    z-index: 10000 !important;
}