/* Content Flat Black Theme Setting - Added in v1.5 */


/* Flat Black - Body Setting */

body.flat-black {
    background: #E7E7E7;
}


/* Flat Black -  Navbar Inverse Setting */

.flat-black .navbar.navbar-inverse {
    background: #3A3A3A;
}


/* Flat Black - Sidebar Setting */

.flat-black {
    & .sidebar,
    & .sidebar-bg {
        background: #3A3A3A;
    }
    & .sidebar .nav > li > a {
        color: #b2b2b2;
    }
    & .sidebar.sidebar-grid .nav > li > a {
        border-bottom: 1px solid #474747;
        border-top: 1px solid #474747;
    }
    & .sidebar .nav > li.active > a,
    & .sidebar .active .sub-menu > li.active > a,
    & .sidebar .sub-menu > li > a:hover,
    & .sidebar .sub-menu > li > a:focus {
        color: #fff;
    }
    & .sidebar .sub-menu > li > a,
    & .sidebar .sub-menu > li > a:before {
        color: #999;
    }
    & .sidebar .nav > li > a:focus, 
    & .sidebar .nav > li > a:hover,
    & .sidebar .nav > li.expand > a, 
    & .page-sidebar-minified .sidebar .nav > li.has-sub:hover > a, 
    & .page-sidebar-minified .sidebar .nav > li.has-sub:focus > a {
        background: #323232;
    }
    & .sidebar .sub-menu,
    & .page-sidebar-minified .sidebar .nav li.has-sub > .sub-menu,
    & .sidebar .nav > li.nav-profile {
        background: #2A2A2A;
    }
}


/* Flat Black - Page Footer Setting */

.flat-black .footer {
    border-color: #D7D7D7;
}


/* Flat Black - List Group item Setting */

.flat-black {
    & .list-group-item.list-group-item-inverse {
        background: #3A3A3A;
        border-color: #242424;
    }
    & .list-group-item.list-group-item-inverse:hover,
    & .list-group-item.list-group-item-inverse:focus {
        background: #323232;
    }
}

/* Flat Black - Nav Pills Setting */

.flat-black {
    & .nav-pills > li.active > a, 
    & .nav-pills > li.active > a:focus,
    & .nav-pills > li.active > a:hover {
        background: #2A2A2A;
    }
}


/* Flat Black - Badge Setting */

.flat-black {
    & .badge.badge-inverse, 
    & .label.label-inverse {
        background: #3A3A3A;
    }
    & .badge.badge-default, 
    & .label.label-default {
        background: #C3C3C3;
    }
}


/* Flat Black - Tabs Setting */

.flat-black {
    & .nav-tabs {
        background: #d2d2d2;
    }
    & .nav-tabs.nav-tabs-inverse {
        background: #2A2A2A;
    }
}


/* Flat Black - Panel Setting */

.flat-black {
    & .panel-with-tabs .panel-heading {
        background: #d2d2d2;
    }
    & .panel-inverse > .panel-heading {
        background: #2A2A2A;
    }
}


/* Flat Black - Table Setting */

.flat-black {
    & .table > tbody > tr > td, 
    & .table > tbody > tr > th, 
    & .table > tfoot > tr > td, 
    & .table > tfoot > tr > th, 
    & .table > thead > tr > td, 
    & .table > thead > tr > th {
        border-color: #E0E0E0;
    }
    & .table > thead > tr > th {
        border-color: #E0E0E0 !important;
    }
    & .dataTables_filter input {
        background: #f7f7f7;
        border-color: #f7f7f7;
    }
    & .dataTables_filter input:focus {
        border-color: #D7D7D7;
    }
    & .table-striped > tbody > tr:nth-child(odd) > td, 
    & .table-striped > tbody > tr:nth-child(odd) > th {
        background: #f7f7f7;
    }
}


/* Flat Black - Button Inverse Setting */

.flat-black {
    & .btn.btn-inverse {
        background: #3A3A3A;
        border-color: #242424;
    }
    & .btn-inverse:hover, 
    & .btn-inverse:focus, 
    & .btn-inverse:active, 
    & .btn-inverse.active, 
    & .open .dropdown-toggle.btn-inverse {
        background: #2a2a2a;
    }
}


/* Flat Black - Button Default Setting */

.flat-black {
    & .btn.btn-default {
        background: #c8c8c8;
        border-color: #c8c8c8;
    }
    & .btn.btn-default:hover,
    & .btn.btn-default:focus,
    & .btn.btn-default:active,
    & .btn.btn-default.active, 
    & .open .dropdown-toggle.btn.btn-default {
        background: #a3a3a3;
        border-color: #a3a3a3;
    }
}


/* Flat Black - Button White Setting */

.flat-black {
    & .btn.btn-white,
    & .btn-group .btn.btn-white:not(.active) + .btn.btn-white, 
    & .input-group-btn .btn.btn-white:not(.active) + .btn.btn-white {
        border-color: #d2d2d2;
    }
    & .btn.btn-white:hover,
    & .btn.btn-white:focus,
    & .btn.btn-white:active, 
    & .btn.btn-white.active, 
    & .open .dropdown-toggle.btn.btn-white {
        background: #e0e0e0;
        border-color: #d2d2d2;
    }
}


/* Flat Black - Pagination Setting */

.flat-black {
    & .pager li > a, 
    & .pager li > span, 
    & .pagination > li > a {
        border-color: #d2d2d2;
    }
    & .pagination > .active > a, 
    & .pagination > .active > a:focus, 
    & .pagination > .active > a:hover, 
    & .pagination > .active > span, 
    & .pagination > .active > span:focus, 
    & .pagination > .active > span:hover {
        background: #323232 !important;
        border-color: #323232 !important;
    }
    & .pager li > a:focus, 
    & .pager li > a:hover, 
    & .pager li > span:focus, 
    & .pager li > span:hover, 
    & .pagination > li > a:focus, 
    & .pagination > li > a:hover {
        background: #e0e0e0;
    }
}


/* Flat Black -  Progress Bar Setting */

.flat-black {
    & .progress {
        background: #eaeaea;
    }
    & .widget-stats .stats-progress {
        background: url(../img/transparent/black-0.2.png);
        background: rgba(0,0,0,.2);
    }
}


/* Flat Black - Vector Map Setting */

.flat-black .jvectormap-container {
    background: #2d2d2d !important;
}


/* Flat Black - Helper Classes Setting */

.flat-black {
    & .bg-black {
        background: #3A3A3A !important;
    }
    & .bg-silver {
        background: #f7f7f7 !important;
    }
    & .bg-black-darker {
        background: #2d2d2d !important;
    }
}


/* Flat Black - Bootstrap Wizard Setting */

.flat-black {
    & .bwizard-steps li.active, 
    & .bwizard-steps li.active:hover, 
    & .bwizard-steps li.active:focus {
        background: #00acac !important;
    }
    & .bwizard-steps li:hover, 
    & .bwizard-steps li:focus {
        background: #DFDFDF !important;
    }
    & .bwizard-steps li {
        background: #f7f7f7 !important;
    }
}


/* Flat Black - Timeline */

.flat-black .timeline-footer {
    background: #F2F2F2;
}


/* Flat Black - Invoice */

.flat-black {
    & .invoice-header,
    & .invoice-price {
        background: #F5F5F5;
    }
    & .invoice-price .invoice-price-right {
        background: #3a3a3a;
    }
}


/* Flat Black - Error Page */

.flat-black {
    & .error-content {
        background: #3a3a3a;
    }
    & .error-code {
        color: #3a3a3a;
    }
    & .error-desc {
        color: #888;
    }
}