/* Parsley */

.parsley-errors-list {
    padding: 0 !important;
    list-style-type: none !important;
    margin: 0 !important;
    color: #e5603b;
    font-size: 12px !important;
    line-height: inherit !important;
}
.parsley-errors-list.filled {
    margin-top: 5px !important;
}
.parsley-error,
.parsley-success {
    color: #333 !important;
}
.parsley-error {
    background: #ffdedd !important;
    border-color: @red !important;
}
.parsley-success {
    background: #cceeee !important;
    border-color: @green !important;
}
.parsley-error:focus,
.parsley-success:focus {
    color: #333 !important;
    .box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.3)) !important;
}