/* Responsive Setting */

@media(max-width: 979px) {
    .form-horizontal.form-bordered .form-group {
        border-bottom: 1px solid #eee;
        
        & .control-label {
            padding: 15px;
            line-height: 34px;
        }
        & > div {
            padding: 15px;
        }
        &, 
        & > div,
        & > .control-label {
            border: none;
        }
    }
    .result-list .result-image,
    .result-list .result-price {
        width: 200px;
    }
    .gallery .image {
        width: 33%;
    }
    .page-with-two-sidebar .content {
        margin-right: 0 !important;
    }
    @keyframes sidebarSlideInRight {
        from { right: -220px; }
        to { right: 0; }
    }
    @-webkit-keyframes sidebarSlideInRight {
        from { right: -220px; }
        to { right: 0; }
    }
    .page-with-two-sidebar .sidebar.sidebar-right,
    .page-with-two-sidebar .sidebar-bg.sidebar-right { 
        right: -220px;
        left: auto !important;
        position: fixed;
    }
    .page-with-two-sidebar.page-sidebar-toggled .sidebar.sidebar-right,
    .page-with-two-sidebar.page-sidebar-toggled .sidebar-bg.sidebar-right {
        .animation(none);
    }
    .page-with-two-sidebar.page-right-sidebar-toggled .sidebar.sidebar-right,
    .page-with-two-sidebar.page-right-sidebar-toggled .sidebar-bg.sidebar-right {
        .animation(sidebarSlideInRight .2s);
        right: 0;
        left: auto;
    }
    .theme-panel .control-label.double-line {
        line-height: 30px;
    }
}

@media (max-width: 767px) {
    .page-header-fixed {
        padding-top: 109px;
    }
    .page-with-right-sidebar .sidebar,
    .page-with-right-sidebar .sidebar-bg {
        left: auto;
        right: -220px;
    }
    .page-with-right-sidebar .content {
        margin-right: 0;
    }
    .page-with-right-sidebar .footer {
        margin-right: 20px;
    }
    .content,
    .page-sidebar-minifed .content {
        margin-left: 0 !important;
    }
    .content.content-full-width .breadcrumb {
        margin: 20px;
    }
    .navbar-brand {
        width: auto;
    }
    .navbar-header {
        border-bottom: 1px solid #eee;
    }
    .navbar-inverse .navbar-header {
        border-bottom: 1px solid #2d353c;
    }
    .navbar-nav {
        margin: 0;
    }
    .navbar-right {
        float: right;
    }
    .navbar-left {
        float: left;
    }
    .navbar-nav > li {
        float: left;
    }
    .navbar-form {
        margin: 0;
        padding: 12px 10px;
        border: none;
        .box-shadow(none);
    }
    .navbar-form .btn-search {
        right: 10px;
    }
    .navbar-form .form-group {
        margin: 0;
    }
    .navbar-form .form-control,
    .navbar-form .form-control:focus {
        margin: 0;
        width: 200px !important;
        .animation(none) !important;
    }
    .navbar-nav .open .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        float: left;
        min-width: 160px;
        border-top: 1px solid #eee;
        list-style: none;
        background: #fff;
        .box-shadow(0 2px 5px -1px rgba(0, 0, 0, 0.2));
    }
    .navbar-nav.navbar-right .open .dropdown-menu {
        right: 0;
        left: auto;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
        background-color: #eee;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
        color: #262626;
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
        background: #edf0f5;
        color: #262626;
    }
    .navbar-nav .open .dropdown-menu > li > a {
        padding: 5px 15px;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, 
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        background: #edf0f5;
    }
    @keyframes sidebarSlideInLeft {
        from { left: -220px; }
        to { left: 0; }
    }
    @-webkit-keyframes sidebarSlideInLeft {
        from { left: -220px; }
        to { left: 0; }
    }
    @keyframes sidebarSlideInRight {
        from { right: -220px; }
        to { right: 0; }
    }
    @-webkit-keyframes sidebarSlideInRight {
        from { right: -220px; }
        to { right: 0; }
    }
    .page-sidebar-toggled .sidebar {
        left: 0;
        .animation(sidebarSlideInLeft .2s);
    }
    .page-sidebar-toggled .sidebar-bg {
        position: fixed;
        background: #2d353c;
        left: 0;
        .animation(sidebarSlideInLeft .2s);
    }
    .page-sidebar-toggled.page-with-right-sidebar .sidebar {
        left: auto;
        right: 0;
        .animation(sidebarSlideInRight .2s);
    }
    .sidebar {
        position: absolute;
        left: -220px;
        padding-top: 0;
        z-index: 1030;
    }
    .sidebar-bg {
        position: initial;
        background: none;
        left: -60px;
        z-index: 1020;
    }
    .page-sidebar-minified.page-sidebar-minified .sidebar {
        position: fixed !important;
    }
    .page-sidebar-toggled.page-sidebar-minified .sidebar-bg {
        position: fixed;
        background: #2d353c;
        z-index: 1010;
        left: 0;
        .animation(sidebarSlideInLeft .2s);
    }
    .page-with-right-sidebar.page-sidebar-toggled.page-sidebar-minified .sidebar-bg {
        left: auto;
        right: 0;
        .animation(sidebarSlideInRight .2s);
    }
    .page-sidebar-minified.page-with-right-sidebar .content {
        margin-right: 0;
    }
    .page-sidebar-minified.page-with-right-sidebar .footer {
        margin-right: 20px;
    }
    .content {
        margin-left: 0;
        padding: 20px;
    }
    .breadcrumb {
        float: none !important;
    }
    .footer {
        margin: 0 20px;
    }
    .table-responsive .table > thead > tr > th, 
    .table-responsive .table > tbody > tr > th, 
    .table-responsive .table > tfoot > tr > th, 
    .table-responsive .table > thead > tr > td, 
    .table-responsive .table > tbody > tr > td, 
    .table-responsive .table > tfoot > tr > td {
        white-space: nowrap;
    }
    .login .login-header,
    .login-content {
        width: 100% !important;
    }
    .login {
        margin: 108px 0;
    }
    .login .login-header {
        left: 0;
        margin: 0;
    }
    .invoice-company .pull-right {
        float: none !important;
        display: block;
    }
    .invoice .table-responsive {
        margin-bottom: 20px;
    }
    .invoice-from, .invoice-to, .invoice-date {
        display: block !important;
        width: 100% !important;
        text-align: left !important;
        padding: 0 !important;
    }
    .invoice-to, .invoice-date {
        margin-top: 20px !important;
    }
    .invoice-price .invoice-price-left,
    .invoice-price .invoice-price-right {
        display: block;
        width: 100% !important;
        float: left !important;
        padding: 10px !important;
    }
    .invoice-price .invoice-price-row {
        float: none !important;
        width: 100% !important;
        display: block !important;
    }
    .invoice-price .invoice-price-left .sub-price {
        padding: 0px 10px !important;
        display: block !important;
        text-align: center !important;
    }
    .invoice-price .invoice-price-right {
        text-align: center !important;
    }
    .invoice-price .invoice-price-right small {
        position: relative !important;
        display: block;
        left: 0 !important;
        top: 0 !important;
    }
    .result-list .result-image {
        width: 160px;
    }
    .result-list .result-price {
        width: 100px;
        font-size: 20px;
    }
    .result-list > li > div {
        padding: 15px;
    }
    .map {
        top: 109px !important;
        left: 0 !important;
    }
    .gallery .image {
        width: 50%;
    }
    .vertical-box,
    .vertical-box .vertical-column {
        display: block !important;
    }
    .width-xs,
    .width-sm,
    .width-md,
    .width-lg,
    .width-full {
        width: 100% !important;
    }
    .fc-event .fc-event-title small {
        display: none !important;
    }
    .calendar {
        display: block;
        width: 100% !important;
    }
    .calendar {
        margin-left: 0 !important;
    }
    .fc-button-today {
        float: left !important;
    }
    .fc-header-left,
    .fc-header-center,
    .fc-header-right {
        text-align: center !important;
        width: 100% !important;
        display: table !important;
    }
    .calendar-event .external-event {
        width: 100% !important;
    }
    .fc-header-left {
        text-align: right !important;
    }
    .fc-header-center {
        margin-top: -46px !important;
    }
    .fc-header-center h2 {
        font-size: 18px !important;
    }
    .calendar-event .external-event p,
    .calendar-event .external-event i,
    .fc-header-left .fc-header-space,
    .fc-event .fc-event-icons {
        display: none !important;
    }
    .calendar-event .external-event h5 {
        margin: 0 !important;
    }
    .bwizard-steps li {
        float: left !important;
        width: 100% !important;
    }
    .nav-tabs.nav-justified.nav-justified-mobile > li {
        display: table-cell;
        width: 1%;
    }
    .nav-tabs.nav-justified.nav-justified-mobile > li > a {
        margin-bottom: 0;
    }
    .page-with-two-sidebar .header .navbar-brand {
        float: none;
        display: block;
        margin: 0 70px;
        text-align: center;
    }
    .page-with-two-sidebar .header .navbar-brand .navbar-logo {
        position: relative;
        top: 3px;
        display: inline-block;
        float: none;
        margin-top: 0;
        margin-right: 5px;
    }
    .form-horizontal.form-bordered .form-group > .control-label {
        padding: 15px 15px 0;
        line-height: inherit;
    }
    .form-horizontal.form-bordered .form-group > div {
        padding: 5px 15px 15px;
    }
    .theme-panel {
        top: 0;
        bottom: 0;
    }
    .theme-panel .theme-collapse-btn {
        top: 150px;
    }
    .theme-panel .theme-panel-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        overflow: scroll;
    }
}

@media(max-width: 480px) {
    .media.media-lg .pull-left {
        float: none !important;
        margin-right: 0;
    }
    .media.media-lg .media-object {
        width: 100%;
        margin-bottom: 10px;
    }
    .result-list .result-image {
        width: 100%;
        float: none;
    }
    .result-list .result-image a {
        position: relative;
    }
    .result-list > li > div {
        display: block;
        width: 100%;
    }
    .result-list > li > .result-price {
        border-left: none;
        border-top: 1px dashed #ddd;
        width: 100%;
        padding: 15px;
    }
    .result-list .btn-row {
        text-align: center;
    }
    .result-list .result-price .btn {
        margin-top: 15px;
    }
    .gallery .image {
        width: 100%;
    }
}

@media (max-width: 380px) {
    .navbar-form .form-control,
    .navbar-form .form-control:focus {
        width: 160px !important;
    }
}

@media (max-width: 340px) {
    .navbar-form .form-control,
    .navbar-form .form-control:focus {
        width: 140px !important;
    }
}




/* -------------------------------
   v1.1 Update
------------------------------- */

/* Timeline Setting */

@media (max-width: 979px) {
    .timeline .timeline-body {
        margin-left: 25%;
        margin-right: 10%;
    }
    .timeline .timeline-time {
        width: 13%;
    }
    .timeline .timeline-icon {
        left: 13%;
        width: 12%;
    }
    .timeline:before {
        left: 19%;
    }
}
@media (max-width: 767px) {
    .timeline:before {
        left: 50%;
    }
    .timeline .timeline-body {
        margin-right: 0;
        margin-left: 0;
        margin-top: 10px;
        padding: 20px;
    }
    .timeline .timeline-footer {
        margin: 20px -20px -20px;
        padding: 20px;
    }
    .timeline .timeline-body:before {
        border-bottom-color: #fff;
        border-right-color: transparent;
        left: 50%;
        top: -20px;
        margin-left: -10px;
    }
    .timeline .timeline-time {
        right: 50%;
        left: 0;
        width: auto;
        margin-right: 40px;
        padding-top: 5px;
    }
    .timeline .timeline-icon {
        left: 0;
        width: 80px;
        position: relative;
        margin: 0 auto;
    }
}


/* Coming Soon Page Setting */

@media (max-width: 979px) {
    .coming-soon .timer .is-countdown {
        width: 767px;
    }
}
@media (max-width: 767px) {
    .coming-soon .timer .is-countdown {
        width: 100%;
    }
}
@media (max-width: 480px) {
    .coming-soon .timer .countdown-amount {
        font-size: 24px;
    }
    .coming-soon .timer .countdown-period,
    .coming-soon .coming-soon-header .desc,
    .coming-soon .coming-soon-content .desc {
        font-size: 12px;
    }
    .coming-soon .coming-soon-header .desc,
    .coming-soon .coming-soon-content .desc {
        padding-right: 20px;
        padding-left: 20px;
    }
    .coming-soon .coming-soon-content .input-group {
        width: auto;
        margin-right: 20px;
        margin-left: 20px;
    }
}


/* -------------------------------
   v1.2 Update
------------------------------- */

/* Login V2 Setting */

@media (max-width: 767px) {
    .login.login-v2 {
        margin: 108px 0;
        width: 100%;
    }
    .login-cover-image img {
        max-width: initial;
        max-height: 100%;
    }
    .login-bg-list {
        position: relative;
        padding: 20px;
        right: 0;
        bottom: 0;
        margin-top: -108px;
    }
}


/* -------------------------------
   v1.3 Update
------------------------------- */

/* Widget Chart Setting */

@media (max-width: 540px) {
    .widget-chart .widget-chart-content {
        margin-right: 0;
    }
    .widget-chart .widget-chart-sidebar,
    .widget-chart .widget-chart-sidebar .chart-legend {
        position: relative;
        width: auto;
    }
    .widget-chart .widget-chart-sidebar .chart-legend {
        margin-top: 20px;
    }
}


/* Gallery v2 - Superbox Setting */

@media (max-width: 979px) {
    .superbox-list {
        width: 20%;
    }
}
@media (max-width: 767px) {
    .superbox-list {
        width: 25%;
    }
}
@media (max-width: 480px) {
    .superbox-list {
        width: 50%;
    }
}


/* Vertical Box Setting */
@media (max-width: 767px) {
    .vertical-box,
    .vertical-box-row,
    .vertical-box-column,
    .vertical-box-cell,
    .vertical-box-inner-cell,
    .page-content-full-height .content {
        display: block !important;
        width: auto !important;
        position: relative !important;
        overflow: initial !important;
        table-layout: initial !important;
        top: auto !important;
        height: auto !important;
    }
    .vertical-box .slimScrollBar,
    .vertical-box .slimScrollRail {
        display: none !important;
    }
}


/* -------------------------------
   v1.4 Update
------------------------------- */

/* Page - Inbox V2 */
@media (max-width: 480px) {
    .list-email .email-time {
        float: none;
        display: block;
        margin: 0;
    }
}


/* -------------------------------
   v1.5 Update
------------------------------- */

/* Pace Progress Loader */

@media (max-width: 767px) {
    .pace-progress {
        top: 109px;
    }
    .pace .pace-activity {
        top: 120px
    }
}


/* -------------------------------
   v1.7 Update
------------------------------- */

/* Navbar Header Brand Without Background */

@media (max-width: 767px) {
    .navbar-inverse .navbar-header.navbar-header-without-bg {
      background: none;
      border-bottom: 1px solid #666;
      border-bottom: 1px solid rgba(255,255,255, 0.1);
    }
}

/* Top Navbar with Mega Menu */

@media (max-width: 767px) {
    .navbar-collapse {
        float: none !important;
        border-top: none;
        border-bottom: 1px solid #eee;
    }
    .navbar-collapse .nav > li {
        float: none;
    }
    .navbar-collapse .open:before,
    .navbar-collapse .open:after {
        content: '';
        display: table;
        clear: both;
    }
    .navbar-collapse .open .dropdown-menu {
        position: relative;
        box-shadow: none;
        padding-top: 0;
        border-bottom: 1px solid #eee;
        border-radius: 0;
        background: #f4f6f7;
        width: 100%;
    }
    .navbar-collapse .open .dropdown-menu .dropdown-header {
        padding-top: 20px;
        padding-left: 0;
    }
}

/* Wide Sidebar Setting */

@media (max-width: 767px) {
    @keyframes wideSidebarSlideInLeft {
        from { left: -250px; }
        to { left: 0; }
    }
    @-webkit-keyframes wideSidebarSlideInLeft {
        from { left: -250px; }
        to { left: 0; }
    }
    @keyframes wideSidebarSlideInRight {
        from { right: -250px; }
        to { right: 0; }
    }
    @-webkit-keyframes wideSidebarSlideInRight {
        from { right: -250px; }
        to { right: 0; }
    }
    .page-sidebar-toggled.page-with-wide-sidebar .sidebar {
        left: 0;
        animation: wideSidebarSlideInLeft .2s;
        -webkit-animation: wideSidebarSlideInLeft .2s;
    }
    .page-sidebar-toggled.page-with-wide-sidebar .sidebar-bg {
        left: 0;
        animation: wideSidebarSlideInLeft .2s;
        -webkit-animation: wideSidebarSlideInLeft .2s;
    }
    .page-sidebar-toggled.page-with-right-sidebar.page-with-wide-sidebar .sidebar,
    .page-sidebar-toggled.page-with-right-sidebar.page-with-wide-sidebar .sidebar-bg,
    .page-right-sidebar-toggled.page-with-wide-sidebar.page-with-two-sidebar .sidebar.sidebar-right,
    .page-right-sidebar-toggled.page-with-wide-sidebar.page-with-two-sidebar .sidebar-bg.sidebar-right {
        left: auto;
        right: 0;
        animation: wideSidebarSlideInRight .2s;
        -webkit-animation: wideSidebarSlideInRight .2s;
    }
    .page-with-wide-sidebar .sidebar,
    .page-with-wide-sidebar .sidebar-bg {
        left: -250px;
        width: 250px;
    }
    .page-with-wide-sidebar.page-with-right-sidebar .sidebar,
    .page-with-wide-sidebar.page-with-right-sidebar .sidebar-bg,
    .page-with-wide-sidebar.page-with-two-sidebar .sidebar.sidebar-right, 
    .page-with-wide-sidebar.page-with-two-sidebar .sidebar-bg.sidebar-right {
        left: auto;
        right: -250px;
    }
    .page-with-wide-sidebar.page-with-right-sidebar .content {
        margin-right: 0;
    }
}

/* Login & Register Page with News Feed Setting */

@media (max-width: 992px) {
    .login.login-with-news-feed .news-caption,
    .register.register-with-news-feed .news-caption {
        font-size: 12px;
        padding: 15px 30px;
    }
    .login.login-with-news-feed .news-caption .caption-title,
    .register.register-with-news-feed .news-caption .caption-title {
        font-size: 20px;
    }
}
@media (max-width: 768px) {
    .login.login-with-news-feed .news-feed,
    .register.register-with-news-feed .news-feed {
        display: none;
    }
    .login.login-with-news-feed .right-content,
    .register.register-with-news-feed .right-content {
        float: none;
        width: auto;
    }
    .login.login-with-news-feed .login-header .icon {
        right: 60px;
    }
}
@media (max-width: 480px) {
    .login.login-with-news-feed .login-header .icon {
        display: none;
    }
    .login.login-with-news-feed .login-header,
    .register.register-with-news-feed .register-header {
        padding: 40px 40px 0;
    }
    .login.login-with-news-feed .login-content,
    .register.register-with-news-feed .register-content {
        padding: 20px 40px;
    }
}
@media (max-width: 360px) {
    .login.login-with-news-feed .login-header,
    .register.register-with-news-feed .register-header {
        padding: 40px 30px 0;
    }
    .login.login-with-news-feed .login-content,
    .register.register-with-news-feed .register-content {
        padding: 20px 30px;
    }
}