/* Top Navbar Mega Menu Setting */

.dropdown.dropdown-lg {
    position: static;
}
.dropdown-menu.dropdown-menu-lg {
    left: 20px;
    right: 20px;
    padding: 20px;
}
.dropdown-header {
    font-size: 14px;
    font-weight: bold;
    color: #242a30;
    padding: 0;
    margin: 0 0 10px;
}
.dropdown-menu.dropdown-menu-lg .nav > li > a {
    padding: 0;
    background: none;
    line-height: 24px;
}
.navbar-default .navbar-nav > .active > a, 
.navbar-default .navbar-nav > .active.open > a,
.navbar-default .navbar-nav > .active > a:focus, 
.navbar-default .navbar-nav > .active > a:hover {
    background: #f4f6f7 !important;
    opacity: 1.0;
    filter: alpha(opacity=100);
}