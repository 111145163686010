/* jQuery Autocomplete */

.ui-widget {
    font-family: @body_font_family;
    font-size: 12px;
}
.ui-autocomplete {
    padding: 5px 0 !important;
    border: 1px solid #ccc !important;
    .box-shadow(0 2px 5px -1px rgba(0, 0, 0, 0.2)) !important;
}
.ui-stats-hover, 
.ui-widget-content .ui-stats-hover, 
.ui-widget-header .ui-stats-hover, 
.ui-stats-focus, 
.ui-widget-content .ui-stats-focus, 
.ui-widget-header .ui-stats-focus {
    border: none !important;
    background: @blue !important;
    color: #fff !important;
    margin: 0 !important;
    .border-radius(0) !important;
    
}
.ui-menu .ui-menu-item a {
    padding: 5px 10px;
}