/* List Group Item Inverse Setting */

.list-group-item.list-group-item-inverse {
    background: @black;
    border-color: @dark_black;
    color: #fff;
    font-weight: 300;
}
.list-group-item.list-group-item-inverse .label-inverse, 
.list-group-item.list-group-item-inverse .badge-inverse {
    background: @dark_black;
    background: rgba(0,0,0,0.4);
}
.list-group-item.list-group-item-inverse:hover, 
.list-group-item.list-group-item-inverse:focus {
    color: #fff;
    background: #282F35;
}