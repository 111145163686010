/* Todolist */

.todolist {
    list-style-type: none;
    margin: 0;
    padding: 0;
    
    & i {
        display: block;
        width: 14px;
        text-align: center;
    }
    
    & > li {
        border-bottom: 1px solid #ddd;
    }
    
    & > li > a {
        display: block;
        color: #333;
        
        &:hover,
        &:focus {
            background: #fafafa;
            text-decoration: none;
            
            .todolist-input i:before {
                content: '\f14a';
                color: @black;
                .opacity(0.4);
            }
        }
    }
    
    & > li.active > a {
        &.todolist-input i:before,
        &:hover .todolist-input i:before,
        &:focus .todolist-input i:before {
            content: '\f14a';
            color: #00acac;
            .opacity(1.0);
        }
        & .todolist-title {
            text-decoration: line-through;
        }
    }
    
    & .todolist-input,
    & .todolist-title {
        display: table-cell;
        padding: 10px;
    }
    
    & .todolist-title {
        padding-right: 15px;
    }
    
    & .todolist-input {
        text-align: center;
        font-size: 14px;
        border-right: 1px solid #ddd;
        padding-left: 15px;
    }
}