/* Dropdown Menu */

.dropdown-menu {
    border: none;
    font-size: 12px;
    .box-shadow(0 2px 5px -1px rgba(0, 0, 0, 0.2));
}
.dropdown-menu > li > a {
    padding: 5px 15px;
}
.dropdown-menu > li > a:hover, 
.dropdown-menu > li > a:focus {
    background: #edf0f5;
}
.dropdown-menu > .active > a, 
.dropdown-menu > .active > a:hover, 
.dropdown-menu > .active > a:focus {
    background: #348fe2;
}
.dropdown-menu .divider {
    border-color: #eee;
}
.dropdown-menu.media-list {
    max-width: 280px;
    padding: 0;
}
.dropdown-menu.media-list p {
    text-overflow: ellipsis;
    overflow: hidden;
}
.dropdown-menu.media-list .dropdown-header {
    padding: 10px 20px !important;
    background: #fafafa;
}
.dropdown-menu.media-list > .media {
    margin-top: 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-bottom: -1px;
}
.dropdown-menu.media-list > .media > a {
    display: block;
    padding: 10px 20px !important;
}
.dropdown-menu.media-list > .media .media-object {
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    margin-right: 10px;
    .border-radius(50%);
}
.dropdown-footer {
    padding: 10px 20px;
}
.dropdown-menu > li.dropdown-footer > a {
    padding: 0 !important;
    display: inline !important;
}
.dropdown-menu > li.dropdown-footer > a:hover,
.dropdown-menu > li.dropdown-footer > a:focus {
    background: none !important;
    text-decoration: underline !important;
}