/*  Plugins Select 2 Choice */

.select2-container.form-control .select2-choice, 
.select2-container.form-control .select2-choice {
    background: none !important;
    margin: -6px -12px !important;
    padding: 6px 12px !important;
    line-height: 20px !important;
    border:none !important;
    height: 32px !important;
    .box-shadow(none) !important;
}
.select2-container.form-control.input-sm .select2-choice, 
.select2-container.form-control.input-sm .select2-choice {
    height: 30px !important;
}
.select2-container .select2-choice .select2-arrow {
    background: none !important;
    border-left: 1px solid #e2e7eb !important;
}
.select2-container.form-control .select2-choice abbr {
    top: 10px !important;
}
.select2-container.form-control.input-sm .select2-choice abbr {
    top: 9px !important;
}
.select2-container .select2-choice .select2-arrow b {
    background-position: 0 3px !important;
}
.select2-container.form-control.input-sm .select2-choice .select2-arrow b {
    background-position: 0 2px !important;
}
.select2-drop-active {
    border-color: #e2e7eb !important;
    .box-shadow(0 2px 2px -1px rgba(0,0,0,0.2)) !important;
}
.select2-drop.select2-drop-above {
    .box-shadow(0 -2px 2px -1px rgba(0,0,0,0.2)) !important;
}
.select2-search input {
    border-color: #e2e7eb !important;
    .border-radius(4px) !important;
}
.select2-drop {
    border-color: #e2e7eb !important;
    padding-top: 5px !important;
    border-top: 1px solid #e2e7eb !important;
}
.select2-results .select2-highlighted {
    background: #edf0f5 !important;
    color: #222 !important;
}
.select2-container.select2-container-multi.form-control {
    height: auto !important;
    width: 240px !important;
}
.select2-container.select2-container-multi.form-control .select2-choices {
    margin: -6px -12px !important;
    border: none !important;
    min-height: 22px !important;
    height: auto !important;
    background: none !important;
    .box-shadow(none) !important;
}
.select2-container-multi .select2-choices .select2-search-choice {
    border-color: #ddd !important;
    background: #fff !important;
    .box-shadow(none) !important;
}