/* Alert */

.alert {
    border: none;
}
.alert.alert-success {
    background: #7cdda7;
}
.alert.alert-info {
    background: #93cfe5;
}
.alert.alert-danger {
    background: #f8b2b2;
}
.alert.alert-warning {
    background: #ffead0;
}


/* Note Setting */

.note {
    margin-bottom: 20px;
    padding: 15px;
    border-left: 3px solid;
}
.note.note-success {
    border-color: #4a8564;
    background: #b0ebca;
    color: #3c763d;
}
.note.note-success h1, 
.note.note-success h2, 
.note.note-success h3, 
.note.note-success h4, 
.note.note-success h5, 
.note.note-success h6 {
    color: #3c763d;
}
.note.note-danger {
    border-color: #986e6e;
    background: #fbd1d1;
    color: #a94442;
}
.note.note-danger h1, 
.note.note-danger h2, 
.note.note-danger h3, 
.note.note-danger h4, 
.note.note-danger h5, 
.note.note-danger h6 {
    color: #a94442;
}
.note.note-info {
    border-color: #587c89;
    background: #bee2ef;
    color: #31708f;
}
.note.note-info h1, 
.note.note-info h2, 
.note.note-info h3, 
.note.note-info h4, 
.note.note-info h5, 
.note.note-info h6 {
    color: #31708f;
}
.note.note-warning {
    border-color: #9d9080;
    background: #fff2e3;
    color: #8a6d3b;
}
.note.note-warning h1, 
.note.note-warning h2, 
.note.note-warning h3, 
.note.note-warning h4, 
.note.note-warning h5, 
.note.note-warning h6 {
    color: #8a6d3b;
}