/* Combobox */

.combobox-container {
    display: table;
    border-collapse: separate;
    width: 100%;
}
.combobox-container input, .combobox-container .uneditable-input {
    border: 1px solid #edf0f5;
    background: #edf0f5;
    font-size: 12px;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    line-height: 1.42857143;
    color: #555;
    .border-radius(4px 0 0 4px) !important;
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.combobox-container input:focus, .combobox-container .uneditable-input:focus {
    outline: 0;
    border-color: #d8dce2;
    .box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.12));
}
.combobox-container .add-on,
.combobox-container input {
    display: table-cell !important;
}
.combobox-container .add-on {
    width: 1% !important;
    padding: 5px 10px !important;
    white-space: nowrap;
    float: none !important;
    background: #d6d8dd !important;
    color: #555 !important;
}
.btn .combobox-clear {
    margin: 0 !important;
    width: 8px !important;
    position: relative;
    .opacity(1.0);
}
.combobox-container .icon-remove:before {
    content: '\f00d';
    font-family: FontAwesome;
    font-size: 14px;
    font-style: normal;
    position: absolute;
    right: -2px;
    top: 1px;
    text-shadow: none;
}
