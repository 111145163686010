/* Datepicker */

.datepicker table tr td.today, 
.datepicker table tr td.today:hover, 
.datepicker table tr td.today.disabled, 
.datepicker table tr td.today.disabled:hover {
    background: #eee !important;
}
.datepicker table tr td.active:hover, 
.datepicker table tr td.active:hover:hover, 
.datepicker table tr td.active.disabled:hover, 
.datepicker table tr td.active.disabled:hover:hover, 
.datepicker table tr td.active:focus, 
.datepicker table tr td.active:hover:focus, 
.datepicker table tr td.active.disabled:focus, 
.datepicker table tr td.active.disabled:hover:focus, 
.datepicker table tr td.active:active, 
.datepicker table tr td.active:hover:active, 
.datepicker table tr td.active.disabled:active, 
.datepicker table tr td.active.disabled:hover:active, 
.datepicker table tr td.active.active,
.datepicker table tr td span.active.active,
.datepicker table tr td.active:hover.active, 
.datepicker table tr td.active.disabled.active, 
.datepicker table tr td.active.disabled:hover.active, 
.open .dropdown-toggle.datepicker table tr td.active, 
.open .dropdown-toggle.datepicker table tr td.active:hover, 
.open .dropdown-toggle.datepicker table tr td.active.disabled, 
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover {
    background: #348fe2 !important;
    color: #fff !important;
}
.datepicker {
    min-width: 250px !important;
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
}
.datepicker.dropdown-menu {
    min-width: 250px;
    border: 1px solid #ddd !important;
    .box-shadow(0 2px 5px -1px rgba(0, 0, 0, 0.2)) !important;
}
.datepicker-dropdown:before {
    border-bottom-color: #ddd !important;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
    border-top-color: #ddd !important;
}
.datepicker table {
    width: 100%;
}	        
.input-daterange .input-group-addon {
    background: #d6d8dd !important;
    border: none !important;
    text-shadow: none !important;
}
.irs-from, .irs-to, .irs-single {
    background: @black !important;
}
.datepicker-full-width .datepicker-inline {
    width: 100%;
}