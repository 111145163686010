/* Badge & Label Setting */

.badge {
    font-size: 75%;
    line-height: 1.25;
    font-weight: 600;
}
.label {
    font-size: 75%;
    font-weight: 600;
}
.badge.badge-square {
    .border-radius(0);
}
.badge.badge-default,
.label.label-default {
    background: @grey;
}
.badge.badge-danger,
.label.label-danger {
    background: @red;
}
.badge.badge-warning,
.label.label-warning {
    background: @orange;
}
.badge.badge-success,
.label.label-success {
    background: @green;
}
.badge.badge-info,
.label.label-info {
    background: @aqua ;
}
.badge.badge-primary,
.label.label-primary {
    background: @blue ;
}
.badge.badge-inverse,
.label.label-inverse {
    background: @black ;
}
