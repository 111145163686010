/* Well */

.well {
    padding: 15px;
    background: #fff;
    .box-shadow(none);
    &.well-sm {
        padding: 10px;
    }
    &.well-lg {
        padding: 30px;
    }
}