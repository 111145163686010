/* Content & content elements */

.content {
    margin-left: 220px;
    padding: 20px 25px;
}
.content.content-full-width {
    padding: 0;
}
.content.content-full-width .page-header {
    margin: 20px;
}
.content.content-full-width .breadcrumb {
    margin: 0 20px 0 0;
}
.content.content-inverse-mode .page-header,
.content.content-inverse-mode .breadcrumb a,
.content.content-inverse-mode h1,
.content.content-inverse-mode h2,
.content.content-inverse-mode h3,
.content.content-inverse-mode h4,
.content.content-inverse-mode h5,
.content.content-inverse-mode h6 {
    color: #fff;
}
.content.content-full-width .page-header,
.content.content-full-width .breadcrumb {
    position: relative;
    z-index: 10;
}
.content.content-full-width .breadcrumb {
    z-index: 20;
}
.page-header {
    font-size: 24px;
    margin: 0 0 20px;
    padding: 0;
    border: none;
    line-height: 28px;
}
.breadcrumb {
    padding: 0;
    margin: 0;
    background: none;
}
.breadcrumb > li {
    line-height: 28px;
}
.ie8 .breadcrumb > li {
    display: inline;
}
.breadcrumb > li a {
    color: #333;
}