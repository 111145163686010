/* Vector / Google Map */
            
.page-sidebar-minified .map {
    left: 60px;
}
.map {
    position: absolute;
    top: 54px;
    bottom: 0;
    left: 220px;
    right: 0;
    z-index: 0;
    
    & .jvectormap-zoomin,
    & .jvectormap-zoomout {
        width: 20px !important;
        height: 20px !important;
        font-size: 14px;
        line-height: 14px;
        left: 15px;
    }
    & .jvectormap-zoomin {
        top: 60px;
    }
    & .jvectormap-zoomout {
        top: 90px;
    }
}
.map-float-table {
    position: absolute;
    right: 25px;
    bottom: 25px;
    background: url(../img/transparent/black-0.6.png);
    background: rgba(0,0,0,0.6);
}
.map-content {
    padding: 0 25px;
    z-index: 100;
    position: relative;
}
