/* Jumbotron */

.jumbotron {
    background: #f0f3f4;
    
    & h1,
    & .h1 {
        font-size: 56px;
    }
    & p {
        font-size: 18px;
    }
}