/* jVectorMap */
			    
.jvectormap-zoomin, .jvectormap-zoomout {
    width: 16px !important;
    height: 16px !important;
}
.jvectormap-label {
    padding: 3px 6px !important;
    border: none !important;
    background: url(../img/transparent/black-0.6.png) !important;
    background: rgba(0,0,0,0.6) !important;
}
