/* DateTimepicker  */

.datetimepicker {
    & table tr td.active,
    & table tr span.active {
        background-image: none !important;
        background: @blue !important;
        color: #fff !important;
    }
    & table {
        width: 100%;
    }
    & th, 
    & td {
        padding: 0px 5px !important;
        width: 30px !important;
        height: 30px !important;
    }
}
