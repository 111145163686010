/* CKEditor / wysihtml5 */

.panel-form .cke_chrome {
    border: none;
    .box-shadow(none);
}
.panel-form .wysihtml5-toolbar {
    padding: 15px 15px 5px;
}
.panel-form .wysihtml5-sandbox {
    .border-radius(0 0 4px 4px) !important;
}