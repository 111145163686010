/* Data Table */

.dataTables_length select,
.dataTables_filter input {
    border: 1px solid #bec0c4;
    background: #edf0f5;
    font-size: 12px;
    padding: 6px 12px;
    line-height: 1.42857143;
    color: #555;
    .border-radius(3px);
}
.dataTables_length select {
    margin-right: 10px;
}
.dataTables_filter input {
    border-color: #edf0f5;
    height: 34px;
    margin-left: 10px;
}
.dataTables_filter input:focus {
    outline: 0;
    border-color: #bec0c4;
    .box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.12));
}